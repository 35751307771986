<template>
	<div class="modal" style="display:block">
		<!-- <div class="modal-content" v-bind:class="{ 'bravo' : hasError === false}"> -->
        <div class="modal-content">

            <template v-if="accesRefuseIndicesManquants == true">
                <div class="ordinateur">
                    <p class="message">Il semblerait que vous n’ayez pas collecté tous les indices pour pouvoir accéder à l'ordinateur.</p>
                </div>
            </template>


            <template v-if="accesRefuseIndicesManquants == false">
                <template v-if="step == 1">
                    <div class="ordinateur">
                        <p>Entrez votre mot de passe</p>
                        <input ref="password" v-model="form.password" type="text" maxlength="10">

                        <p v-if="hasError == true">Mot de passe erroné, veuillez réessayer</p>

                        <button v-if="hasError !== false" type="button" class="btn btn-blue align-center" @click="checkPassword()"><span>Valider</span></button>
                    </div>
                </template>

                <template v-if="step == 2">
                    <div class="ordinateur-applis">
                        <span class="number">1</span>
                        <span class="link" @click="goNext()"></span>
                        <!-- <span class="link link-ordo" @click="lanceSerieQuiz()"></span> -->
                    </div>
                </template>

                <template v-if="step == 3">
                    <div class="ordi-emails step1">
                        <button class="btn btn-blue btn-ouvrir" @click="goNext()">Ouvrir</button>
                    </div>
                </template>

                <template v-if="step == 4">
                    <div class="ordi-emails step2">
                        <button v-if="isAlreadyInDossier('medical', 'mail-reponse-dermato')" class="btn btn-blue btn-pj" @click="goNext()">Voir les pièces jointes</button>
                    </div>
                </template>

                <template v-if="step == 5">
                    <div class="ordi-emails step3">
                        <div class="pj">
                            <figure>
                                <img src="img/dossier/bilan-sanguin.png" alt="Bilan sanguin" @click="showFullImage()">
                                <a href="javascript:;" @click="showFullImage()">Agrandir</a>
                            </figure>

                            <div class="zoom" :class="{ 'show' : fullImage}" @click="hideFullImage()">
                                <img src="img/dossier/bilan-sanguin.png" alt="Bilan sanguin">
                                <span class="close"></span>
                            </div>

                            <figure>
                                <img src="img/dossier/pieds.jpg" alt="Photo pieds" @click="showFullImage2()">
                                <a href="javascript:;" @click="showFullImage2()">Agrandir</a>
                            </figure>

                            <div class="zoom" :class="{ 'show' : fullImage2}" @click="hideFullImage2()">
                                <img src="img/dossier/pieds.jpg" alt="Photo pieds">
                                <span class="close"></span>
                            </div>
                        </div>
                    </div>
                </template>

                <template v-if="step == 6">
                    <div class="ordinateur-applis">
                        <span class="number number-radio">8</span>
                        <span class="link link-radio" @click="goNext()"></span>
                    </div>
                </template>

                <template v-if="step == 7">
                    <div class="ordi-radios">
                        <div class="visuel">
                            <img src="img/dossier/radios1.jpg" alt="Radio pieds">
                        </div>
                    </div>
                </template>

                <template v-if="step == 8">
                    <div class="ordi-radios">
                        <div class="visuel">
                            <img src="img/dossier/radios2.jpg" alt="Radio pieds">
                        </div>
                    </div>
                </template>

                <template v-if="step == 9">
                    <div class="ordi-radios">
                        <div class="visuel">
                            <img src="img/dossier/radios3.jpg" alt="Radio mains poumons">
                        </div>
                    </div>
                </template>

                <template v-if="step == 10">
                    <div class="ordinateur-applis">
                        <span class="link link-ordo" @click="lanceSerieQuiz()"></span>
                    </div>
                </template>
            </template>

        </div>

        <div class="modal-footer">
            <!-- tant que pas connecté à l'ordi, on laisse le choix de revenir dans le 360 du bureau -->
            <button v-if="hasError !== false && step == 1" type="button" class="btn btn-blue align-left" @click="close()"><span>Retour</span></button>

            <!-- boutons "ajouter au dossier médical" -->
            <button v-if="step == 4 && !isAlreadyInDossier('medical', 'mail-reponse-dermato')" type="button" class="btn btn-blue align-center" @click="addToDossier('medical', 'mail-reponse-dermato')"><span>Ajouter au dossier médical</span></button>
            <button v-if="(step == 5 && !fullImage && !fullImage2 && !isAlreadyInDossier('medical', 'bilan-sanguin')) || (step == 5 && !fullImage && !fullImage2 && !isAlreadyInDossier('medical', 'photo-pieds'))" type="button" class="btn btn-blue align-center" @click="addToDossier('medical', ['bilan-sanguin','photo-pieds'])"><span>Ajouter au dossier médical</span></button>
            <button v-if="step == 7 && !isAlreadyInDossier('medical', 'radio-pieds-1')" type="button" class="btn btn-blue align-center" @click="addToDossier('medical', 'radio-pieds-1')"><span>Ajouter au dossier médical</span></button>
            <button v-if="step == 8 && !isAlreadyInDossier('medical', 'radio-pieds-2')" type="button" class="btn btn-blue align-center" @click="addToDossier('medical', 'radio-pieds-2')"><span>Ajouter au dossier médical</span></button>
            <button v-if="step == 9 && !isAlreadyInDossier('medical', 'radio-mains-poumons')" type="button" class="btn btn-blue align-center" @click="addToDossier('medical', 'radio-mains-poumons')"><span>Ajouter au dossier médical</span></button>

            <!-- bouton "suivant" sur les écrans sans action ou quand l'item affiché a déjà été ajouté au dossier  -->
            <button v-if="(step == 5 && isAlreadyInDossier('medical', 'bilan-sanguin') && isAlreadyInDossier('medical', 'photo-pieds')) || (step == 7 && isAlreadyInDossier('medical', 'radio-pieds-1')) || (step == 8 && isAlreadyInDossier('medical', 'radio-pieds-2')) || (step == 9 && isAlreadyInDossier('medical', 'radio-mains-poumons'))" type="button" class="btn btn-blue align-right" @click="goNext()"><span>Suivant</span></button>
        </div>
    </div>
</template>


<script>
import { EventBus } from '@/core/event-bus.js';

export default {
	name: 'OrdiMedecin2',
    data() {
        return {
            step : 1,
            form : {
                password: null,
            },
            hasError: null,
            accesRefuseIndicesManquants: null,
            fullImage: false,
            fullImage2: false
        }
    },
    mounted() {
        if (this.nbMedical == 6 && this.nbRessources == 6 && this.nbIndices == 9) {
        // if (this.nbIndices == 1) { // pour tests plus rapides
            this.accesRefuseIndicesManquants = false
        } else {
            this.accesRefuseIndicesManquants = true
        }
    },
    computed : {
		nbMedical () {
			return this.$store.state.dossiers.medical.length
		},
		nbIndices () {
			return this.$store.state.dossiers.indices.length
		},
		nbRessources () {
			return this.$store.state.dossiers.ressources.length
		},
    },
	methods : {
		close() {
			this.$emit('close')
		},
        goNext(){
            this.step++;
        },
        showFullImage(){
            this.fullImage = true;
        },
        hideFullImage() {
            this.fullImage = false;
        },
        showFullImage2(){
            this.fullImage2 = true;
        },
        hideFullImage2() {
            this.fullImage2 = false;
        },
        checkPassword() {
            if (this.form.password == "1515-toto") {
                this.hasError = false
                this.goNext()
            } else {
                this.hasError = true
            }
        },
        isAlreadyInDossier(dossier, item) {
            return !(this.$store.state.dossiers[dossier].indexOf(item) === -1)
        },
        addToDossier(dossier, items) {
            if (!Array.isArray(items)) {
                items = [items];
            }
            for (let item of items) {
                if (!this.isAlreadyInDossier(dossier, item)) {
                    this.$store.commit("addToDossier", {dossier: dossier, item: item})
                }
            }
        },
        lanceSerieQuiz() {
            EventBus.$emit('changeQuiz', 'qcm-bg-ordonnancier');
        }
        // ecranSuivant() {
        //     // on enchaine sur le QCM imc
        //     let nextQuiz = this.quizList[this.current.enchaineQuizIndex];
        //     EventBus.$emit('changeQuiz', nextQuiz.type, nextQuiz.index);
        // }
    }
}
</script>


<style scoped lang="scss">
    @import "@/assets/css/_variables.scss";

    .modal-content {
        padding: 0;
    }

    .ordinateur {
        background: url('/img/ordinateur.jpg') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        p {
            color: white;
            font-weight: 500;
        }

        input {
            border: none;
            font-size: 4.2rem;
            height: 5.5rem;
            margin-bottom: 3rem;
            text-align: center;
            width: 25rem;
        }

        .btn {
            position: relative;
            left: auto;
            transform: none;
        }

        .message {
            background: white;
            border-radius: 2rem;
            color: red;
            padding: 2rem 4rem;
            width: 60%;
        }
    }

    .ordinateur-applis {
        background: url('/img/ordi-open-appli.jpg') no-repeat 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .link {
            cursor: pointer;
            position: absolute;
            height: 17rem;
            width: 14rem;
            top: 11.5rem;
            left: 29.5rem;

            &-radio {
                width: 20rem;
                top: 35rem;
                left: 26.5rem;
            }

            &-ordo {
                left: 58rem;
            }
        }

        .number {
            background: red;
            color: white;
            border-radius: 50%;
            line-height: 3rem;
            height: 3rem;
            width: 3rem;
            position: absolute;
            top: 24.5rem;
            left: 41rem;

            &-radio {
                top: 35rem;
                left: 40rem;
            }
        }
    }

    .ordi-emails {
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        button {
            &:hover {
                background: white;
                color: $turquoise;
            }
        }

        .btn {
            border-radius: 1.5rem;
            font-size: 1.7rem;
            height: 4.2rem;
            padding: 0 2rem;
            position: absolute;

            &:before {
                display: none;
            }

            &-rediger {
                top: 7rem;
                right: 6rem;
            }

            &:disabled {
                color: white !important;
                background: grey;
                border: none !important;
                cursor: default;
            }
        }

        &.step1 {
            background-image: url('/img/ordi-messagerie5.png');

            .btn {
                &-ouvrir {
                    top: 20rem;
                    right: 6rem;
                }
            }
        }

        &.step2 {
            background-image: url('/img/ordi-messagerie6.png');

            .btn-pj {
                top: 7rem;
                right: 6rem;
            }
        }

        &.step3 {
            background-image: url('/img/ordi-messagerie-vide.png');

            .pj {
                display: flex;
                justify-content: space-around;
                align-items: flex-start;

                img {
                    cursor: pointer;
                    display: block;
                    max-height: 25rem;
                    margin-bottom: 1rem;
                }

                figure {
                    text-align: center;
                }

                a {
                    color: black;
                    font-size: 1.6rem;
                    text-decoration: underline;
                    text-transform: uppercase;
                }
            }

            .zoom {
                background: white;
                opacity: 0;
                visibility: hidden;
                position: absolute;
                top: 3rem;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
                z-index: 5000;
                transition: all .3s ease-in-out;

                img {
                    max-height: 100%;
                    margin: 6rem auto 0;
                }

                .close {
                    background: $blue;
                    border-radius: 50%;
                    cursor: pointer;
                    font-size: 4rem;
                    height: 4rem;
                    width: 4rem;
                    position: absolute;
                    top: 3rem;
                    right: 3rem;

                    &:after {
                        content: "×";
                        color: white;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                &.show {
                    opacity: 1;
                    visibility: visible;
                    top: 0;
                }
            }
        }
    }

    .ordi-radios {
        background-image: url('/img/ordi-radiologie.png');
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        .visuel {
            padding-top: 3rem;

            img {
                max-height: 37rem;
            }
        }
    }
</style>